<template>
    <div id="position" class="box around">
          <!-- <img :src="imgSrc" alt="" class="img"> -->
        <!-- <el-carousel trigger="click" width="100%">
            <el-carousel-item v-for="item in imgs" :key="item">
                <img :src="item"/>
            </el-carousel-item>
        </el-carousel> -->

        <!-- <iframe id="container" frameborder="no" style="width:100%;height:100%;" src="./static/index.html"></iframe> -->

    </div>
</template>

<script>
    export default {
        data() {
            return {
                projectId:'',
                imgs:[],
                imgSrc: require('../../assets/images/map/img1.jpg')
            }
        },
        created () {
            this.projectId = sessionStorage.getItem('projectId')
        },
        mounted () {
            this.imgShow();
        },
        methods: {
            imgShow() {
                 this.$axios.get(`${this.baseURL}sys/sysImages/layout/${this.projectId}`)
                    .then(res=>{
                        console.log('位置',res)
                        var data= res.data.data;
                        // this.imgId.push(res.data.data.id);
                        // ${this.baseURL}sys/sysImages/img/`+res.data.data[i].id
                        console.log('data', data)

                        for(var i=0; i<data.length; i++){
                            console.log(111)
                            this.imgs.push(`${this.baseURL}sys/sysImages/img/`+data[i].id)
                            console.log(222)
                        }

                        if(data.length ==0){
                            this.imgs.push(this.imgSrc);
                        }
                        console.log('this.imgs', this.imgs)

                    })  
                    .catch(err=>{
                });
            }
        },
    }
</script>

<style lang="scss" scoped>
    #position{
        width: 100%;
        height: 100%;
    }
    .img{
        width: 100%;
        height: 100%;
    }
    ::v-deep .el-carousel{
        width: 100%;
        height: 100%;
    }
    ::v-deep .el-carousel__container{
        height: 100%;
    }
    ::v-deep  .el-carousel__item  img{
        // width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    #container{
        // border: 1px solid red;
    }
</style>